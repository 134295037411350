import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { ListModel } from '../models';
import { useDebouncedState } from './debounced-state.hook';

export const useQueryParams = ({
    defaultPage = 1,
    defaultPageSize = 10,
    defaultSort = [{ field: 'name', sort: 'asc' as GridSortDirection }],
}: { data?: ListModel<any>; defaultPage?: number; defaultPageSize?: number; defaultSort?: GridSortModel } = {}) => {
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sort, setSort] = useState<GridSortModel>(defaultSort);
    const [debouncedSearch, search, setSearch] = useDebouncedState('');

    return {
        page,
        setPage,
        pageSize,
        setPageSize,
        sort,
        setSort,
        debouncedSearch,
        search,
        setSearch,
    };
};
