import { Paper } from '@mui/material';
import { ComponentProps, FC } from 'react';

export const Panel: FC<ComponentProps<typeof Paper>> = ({ children, ...props }) => {
    return (
        <Paper variant="outlined" {...props}>
            {children}
        </Paper>
    );
};
