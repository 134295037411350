import { Box, Grid } from '@mui/material';
import { ComponentProps, FC } from 'react';

export const PanelContent: FC<ComponentProps<typeof Box>> = ({ children, ...props }) => {
    return (
        <Box sx={{ p: 3 }} {...props}>
            <Grid container direction="column" spacing={2}>
                {children}
            </Grid>
        </Box>
    );
};
