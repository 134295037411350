import { GridSortDirection, GridSortItem } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface IQueryParams {
    page?: number;
    pageSize?: number;
    filter: Record<string, any>;
    sort: GridSortItem[];
    search: string;
}

export interface QueryParamsContextReturnType {
    queryParamsByEntity: Record<string, IQueryParams>;
    debouncedQueryParamsByEntity: Record<string, IQueryParams>;
    setQueryParamsForEntity: (entity: string, queryParams: IQueryParams) => void;
    setFilterForEntity: (entity: string, filter?: IQueryParams['filter']) => void;
    setPageForEntity: (entity: string, page: number) => void;
    setPageSizeForEntity: (entity: string, pageSize: number) => void;
    setSortForEntity: (entity: string, sort: GridSortItem[]) => void;
    setSearchForEntity: (entity: string, search: string) => void;
}

export interface QueryParamsHookReturnType {
    queryParams: IQueryParams;
    debouncedQueryParams: IQueryParams;
    setFilter: (filter?: IQueryParams['filter']) => void;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    setSort: (sort: GridSortItem[]) => void;
    setSearch: (search: string) => void;
    setQueryParams: (queryParams: IQueryParams) => void;
}

export const QueryParamsContext = createContext({ queryParamsByEntity: {} } as QueryParamsContextReturnType);

const mergeEntityIntoRecord = (
    record: Record<string, IQueryParams>,
    entity: string,
    params: Partial<IQueryParams>,
): Record<string, IQueryParams> => {
    const originalParams: IQueryParams = record[entity] || {};
    const mergedParams: IQueryParams = { ...originalParams, ...params };
    return { ...record, [entity]: mergedParams };
};

type Props = {
    children?: ReactNode;
};

export const QueryParamsContextProvider: FC<Props> = ({ children }) => {
    const [queryParamsByEntity, setQueryParamsByEntity] = useState<QueryParamsContextReturnType['queryParamsByEntity']>(
        {},
    );
    const [debouncedQueryParamsByEntity, setDebouncedQueryParamsByEntity] = useState<
        QueryParamsContextReturnType['debouncedQueryParamsByEntity']
    >({});

    const handleSetDebounced = useMemo(
        () =>
            debounce((newQueryParamsByEntity: Record<string, IQueryParams>) => {
                setDebouncedQueryParamsByEntity(newQueryParamsByEntity);
            }, 300),
        [],
    );

    useEffect(() => {
        handleSetDebounced(queryParamsByEntity);
    }, [queryParamsByEntity, handleSetDebounced]);

    const setQueryParamsForEntity: QueryParamsContextReturnType['setQueryParamsForEntity'] = useCallback(
        (entity, queryParams) => {
            setQueryParamsByEntity((prev) => ({ ...prev, [entity]: queryParams }));
        },
        [],
    );

    const setPageForEntity: QueryParamsContextReturnType['setPageForEntity'] = useCallback((entity, page) => {
        setQueryParamsByEntity((prev) => mergeEntityIntoRecord(prev, entity, { page }));
    }, []);

    const setPageSizeForEntity: QueryParamsContextReturnType['setPageSizeForEntity'] = useCallback(
        (entity, pageSize) => {
            setQueryParamsByEntity((prev) => mergeEntityIntoRecord(prev, entity, { pageSize }));
        },
        [],
    );

    const setSortForEntity: QueryParamsContextReturnType['setSortForEntity'] = useCallback((entity, sort) => {
        setQueryParamsByEntity((prev) => mergeEntityIntoRecord(prev, entity, { sort }));
    }, []);

    const setSearchForEntity: QueryParamsContextReturnType['setSearchForEntity'] = useCallback((entity, search) => {
        setQueryParamsByEntity((prev) => mergeEntityIntoRecord(prev, entity, { search }));
    }, []);

    const setFilterForEntity: QueryParamsContextReturnType['setFilterForEntity'] = useCallback((entity, filter) => {
        setQueryParamsByEntity((prev) => mergeEntityIntoRecord(prev, entity, { filter }));
    }, []);

    const value: QueryParamsContextReturnType = {
        queryParamsByEntity,
        debouncedQueryParamsByEntity,
        setQueryParamsForEntity,
        setPageForEntity,
        setPageSizeForEntity,
        setSortForEntity,
        setSearchForEntity,
        setFilterForEntity,
    };

    return <QueryParamsContext.Provider value={value}>{children}</QueryParamsContext.Provider>;
};

export const useQueryParamsContext = (
    entity: string,
    { page = 1, pageSize = 10, search = '', sort = [{ field: 'name', sort: 'asc' as GridSortDirection }], filter = {} },
): QueryParamsHookReturnType => {
    const {
        setQueryParamsForEntity,
        queryParamsByEntity,
        debouncedQueryParamsByEntity,
        setPageForEntity,
        setPageSizeForEntity,
        setSortForEntity,
        setSearchForEntity,
        setFilterForEntity,
    } = useContext(QueryParamsContext);

    const queryParams = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { page, pageSize, search, sort, filter, ...queryParamsByEntity[entity] };
    }, [page, pageSize, search, sort, filter, queryParamsByEntity, entity]);

    const debouncedQueryParams = useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => ({ page, pageSize, search, sort, filter, ...debouncedQueryParamsByEntity[entity] }),
        [debouncedQueryParamsByEntity, entity, filter, page, pageSize, search, sort],
    );

    const setQueryParams: QueryParamsHookReturnType['setQueryParams'] = useCallback(
        (newQueryParams) => {
            setQueryParamsForEntity(entity, newQueryParams);
        },
        [entity, setQueryParamsForEntity],
    );

    const setPage: QueryParamsHookReturnType['setPage'] = useCallback(
        (page) => {
            setPageForEntity(entity, page);
        },
        [entity, setPageForEntity],
    );

    const setPageSize: QueryParamsHookReturnType['setPageSize'] = useCallback(
        (pageSize) => {
            setPageSizeForEntity(entity, pageSize);
        },
        [entity, setPageSizeForEntity],
    );

    const setSort: QueryParamsHookReturnType['setSort'] = useCallback(
        (sort) => {
            setSortForEntity(entity, sort);
        },
        [entity, setSortForEntity],
    );

    const setSearch: QueryParamsHookReturnType['setSearch'] = useCallback(
        (search) => {
            setSearchForEntity(entity, search);
        },
        [entity, setSearchForEntity],
    );

    const setFilter: QueryParamsHookReturnType['setFilter'] = useCallback(
        (filter) => {
            setFilterForEntity(entity, filter);
        },
        [entity, setFilterForEntity],
    );

    return {
        queryParams,
        debouncedQueryParams,
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
        setQueryParams,
    };
};
